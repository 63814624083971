import { Controller, useForm } from 'react-hook-form';
import { IconButton, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import React from 'react';
import { ChatFormData, ChatQuestionInputProps } from './ChatWindow.types';
import { useAppSelector } from '../../store/hooks';

export const ChatQuestionInput = ({ loadingSelector, onSubmit }: ChatQuestionInputProps) => {
  const loading = useAppSelector(loadingSelector);

  const {
    control,
    handleSubmit: handleFormSubmit,
    formState,
    reset,
  } = useForm<ChatFormData>({
    mode: 'onChange',
    defaultValues: {
      message: '',
    },
  });

  const handleSubmit = ({ message }: ChatFormData) => {
    const input = message.trim();
    if (!input) return;
    onSubmit(input);
    reset();
  };

  return (
    <form className='llm-question-input-form' onSubmit={handleFormSubmit(handleSubmit)}>
      <Controller
        name='message'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            fullWidth
            multiline
            disabled={formState.isSubmitting || loading}
            error={!!error}
            helperText={error ? error.message : ''}
            placeholder='Type your message...'
            variant='outlined'
            className='llm-message-input'
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleFormSubmit(handleSubmit)();
              }
            }}
          />
        )}
      />

      <IconButton
        className='llm-submit-button'
        type='submit'
        disabled={!formState.isValid || !formState.isDirty}
      >
        <SendIcon />
      </IconButton>
    </form>
  );
};
