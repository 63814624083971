import React from 'react';
import './ChatModal.scss';
import './Resizer.scss';
import Modal from '@mui/material/Modal';
import { Backdrop } from '@mui/material';
import { closeChatModal } from '../../store/ui/ui.slice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ModalClose } from '../StaticComponents/Modals/ModalClose';
import { DocumentComparePanel } from '../Documents/DocumentCompareModal/DocumentComparePanel/DocumentComparePanel';
import { uiSelectors } from '../../store/ui/ui.selectors';
import { useGetDictionariesQueryDefaults } from '../../store/files/upload/list.service.hooks';
import { AccessMode } from '../../store/files/documentsAndClauses/list.types';
import SplitPane from 'react-split-pane';
import ChatWindow from './ChatWindow';
import { clearChat } from '../../store/files/chat/documentChat.slice';

export const ChatModal = () => {
  const dispatch = useAppDispatch();
  const chatDocument = useAppSelector(uiSelectors.selectModalChat);
  const { data: dictionaries } = useGetDictionariesQueryDefaults({ AccessMode: AccessMode.READ });

  const handleClose = () => {
    dispatch(closeChatModal());
    dispatch(clearChat());
  };

  if (!chatDocument) {
    return null;
  }

  return (
    <Modal
      open={!!chatDocument}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 0,
      }}
      classes={{
        root: 'chat-modal-root',
      }}
    >
      <div className='chat-modal'>
        <SplitPane
          split='vertical'
          minSize={330}
          maxSize={window.innerWidth - 330}
          defaultSize='50%'
          style={{ height: '100%', position: 'relative' }}
        >
          <div className='chat-pane'>
            <DocumentComparePanel document={chatDocument} documentDictionaries={dictionaries} />
          </div>
          <div className='chat-pane'>
            <ChatWindow documentId={chatDocument.DocumentId} />
          </div>
        </SplitPane>

        <ModalClose onClose={handleClose} />
      </div>
    </Modal>
  );
};
